import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Gallery extends Component {
    render() {
        return (
            <div>
                    {/* <!-- page details --> */}
      <div class="using-border py-3">
         <div class="inner_breadcrumb  ml-4">
            <ul class="short_ls">
               <li>
                  <Link to="/">Home</Link>
                  <span>/ /</span>
               </li>
               <li>Gallery</li>
            </ul>
         </div>
      </div>
      {/* <!-- //page details -->
      <!--Gallery-->
      <!-- gallery-main --> */}
      <section class="gallery py-lg-4 py-md-3 py-sm-3 py-3" id="gallery">
         <div class="container py-lg-5 py-md-4 py-sm-4 py-3">
            <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2">Our Gallery</h3>
            <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
               <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum 
               </p>
            </div>
            <div class="row gallery-info">
               <div class="col-lg-6 col-md-6 col-sm-6 gallery-img-grid">
                  <div class="gallery-grids">
                    <a href="#gal1"><img src="assets/images/bb1.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-6 gallery-img-grid">
                  <div class="gallery-grids">
                    <a href="#gal2"><img src="assets/images/bb2.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-6 gallery-img-grid gallery-mid-two mt-lg-4 mt-3">
                  <div class="gallery-grids">
                    <a href="#gal3"><img src="assets/images/a1.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-6 gallery-img-grid gallery-mid-two mt-lg-4 mt-3">
                  <div class="gallery-grids">
                        <a href="#gal4"><img src="assets/images/a2.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-6 gallery-img-grid gallery-mid-two mt-lg-4 mt-3">
                  <div class="gallery-grids">
                    <a href="#gal5"><img src="assets/images/a3.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
               <div class="col-lg-3 col-md-3 col-sm-6 gallery-img-grid gallery-mid-two mt-lg-4 mt-3">
                  <div class="gallery-grids">
                    <a href="#gal3"><img src="assets/images/a4.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-6 gallery-img-grid mt-lg-4 mt-3">
                  <div class="gallery-grids">
                    <a href="#gal8"><img src="assets/images/g10.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-6 gallery-img-grid mt-lg-4 mt-3">
                  <div class="gallery-grids">
                        <a href="#gal7"><img src="assets/images/g11.jpg" alt="news image" class="img-fluid"/></a>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!--// gallery-main -->
      <!-- popup--> */}
      <div id="gal1" class="popup-effect">
         <div class="popup">
            <img src="assets/images/bb1.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- popup--> */}
      <div id="gal2" class="popup-effect">
         <div class="popup">
                <img src="assets/images/bb2.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- popup--> */}
      <div id="gal3" class="popup-effect">
         <div class="popup">
            <img src="assets/images/a1.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- popup--> */}
      <div id="gal4" class="popup-effect">
         <div class="popup">
            <img src="assets/images/a2.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- popup--> */}
      <div id="gal5" class="popup-effect">
         <div class="popup">
           <img src="assets/images/a3.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- popup--> */}
      <div id="gal6" class="popup-effect">
         <div class="popup">
            <img src="assets/images/a4.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- popup--> */}
      <div id="gal8" class="popup-effect">
         <div class="popup">
             <img src="assets/images/g10.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- popup--> */}
      <div id="gal7" class="popup-effect">
         <div class="popup">
            <img src="assets/images/g11.jpg" alt="Popup Image" class="img-fluid" />
            <a class="close" href="#gallery">&times;</a>
         </div>
      </div>
      {/* <!-- //popup -->
      <!-- //gallery popups -->
      <!-- //gallery --> */}
    
            </div>
        )
    }
}

