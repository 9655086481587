import React, { Component } from 'react'

export default class Footer extends Component {

    constructor(props){
        super(props)
    
        this.state = {
        email: '',
        welcomeMsg: ''
        }
    }
    
    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    formSubmit = (e) => {

        console.log(this.state);
        this.setState({
            welcomeMsg: "Thanks for your subscription."
        })
        e.preventDefault();
    }


    render() {
        const { email, welcomeMsg } = this.state 
        return (
            <div>
                <footer class="py-lg-4 py-md-3 py-sm-3 py-3">
                    <div class="container py-lg-5 py-md-5 py-sm-4 py-3">
                        <div class="row ">
                            <div class="footer-info-bottom col-lg-4 col-md-3">
                                <h4 class="pb-lg-4 pb-md-3 pb-3">Address</h4>
                                <div class="bottom-para ">
                                    <div class="footer-office-hour">
                                        <ul>
                                            <li class="mb-2">
                                                <h6>Address</h6>
                                            </li>
                                            <li>
                                                <p>Melbourne,south Brisbane,<br />QLD 4101,Aurstralia.</p>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li class="mb-2 mt-3">
                                                <h6>Phone</h6>
                                            </li>
                                            <li>
                                                <p>+ 1 (234) 567 8901</p>
                                            </li>
                                            <li class="mb-2 mt-3">
                                                <h6>Email</h6>
                                            </li>
                                            <li>
                                                <p><a href="mailto:info@example.com">info@example.com</a></p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="footer-info-bottom col-lg-4 col-md-4">
                                <h4 class="pb-lg-4 pb-md-3 pb-3">Twitter Us</h4>
                                <div class="footer-office-hour">
                                    <ul>
                                        <li>
                                            <p>sit amet consectetur adipiscing</p>
                                        </li>
                                        <li class="my-1">
                                            <p><a href="mailto:info@example.com">info@example.com</a></p>
                                        </li>
                                        <li class="mb-3"><span>Posted 3 days ago.</span></li>
                                        <li>
                                            <p>sit amet consectetur adipiscing</p>
                                        </li>
                                        <li class="my-1">
                                            <p><a href="mailto:info@example.com">info@example.com</a></p>
                                        </li>
                                        <li><span>Posted 3 days ago.</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="footer-info-bottom col-lg-4 col-md-5">
                                <h4 class="pb-lg-4 pb-md-3 pb-3">NewsLetter</h4>
                                <div class="newsletter">
                                    <form class="d-flex" onSubmit={this.formSubmit}>
                                        <input type="email" name="Your Email" class="form-control" placeholder="Your Email" name="email" required value={email} onChange={this.changeHandler} />
                                        <button class="btn1">
                                            <span class="fa fa-envelope" aria-hidden="true"></span>
                                        </button>
                                    </form>
                                </div>
                                <div class="footer-office-hour mt-3">
                                    <p className="font-weight-bold"> &nbsp; &nbsp; {
                                        this.state.welcomeMsg
                                    }</p>
                                    <p>vehicula velit sagittis vehicula. Duis posuere ex in mollis iaculis. Suspendisse tincidunt velit sagittis vehicula</p>
                                </div>
                                <div class="icons mt-3 ">
                                    <ul>
                                        <li><a href="#"><span class="fa fa-facebook"></span></a></li>
                                        <li><a href="#"><span class="fa fa-twitter"></span></a></li>
                                        <li><a href="#"><span class="fa fa-rss"></span></a></li>
                                        <li><a href="#"><span class="fa fa-vk"></span></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="footer-main-grid txt-center my-3">
                            <h2><a class="" href="#home"><span class="fa fa-ravelry mr-2" aria-hidden="true"></span>Economy</a></h2>
                        </div>
                        <div class="footer-main txt-center ">
                            <p>
                                © 2019 PMEDIA
                  . All Rights Reserved | Design by <a href="http://www.zubaircse.com" target="_blank">Mohammad Zubair</a>
                            </p>
                        </div>
                        {/* <!-- move icon --> */}
                        <div class="txt-center">
                            <a href="#home" class="move-top txt-center mt-3"></a>
                        </div>
                        {/* <!--//move icon --> */}
                    </div>
                </footer>
            </div>
        )
    }
}

