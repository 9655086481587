import React, { Component } from 'react'
import axios from 'axios'

class CreatePost extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             userId:1,
             title:'',
             body:''
        }
    }

    changeHandler = (e)=>{
        this.setState({
            [e.target.name]:e.target.value
        })
    }

    submitHandler = (e)=>{
        e.preventDefault()
        axios.post('https://jsonplaceholder.typicode.com/posts', this.state)
        .then(response=>{
            console.log(response)
        })
        .catch(error=>{
            console.log(error)
        })
        console.log(this.state)
    }
    
    render() {

        const {userId, title, body} = this.state;
        return (
            <div>
                <p>Id: {userId}</p>
                <p>Title: {title}</p>
                <p>Body: {body}</p>
                <form onSubmit={this.submitHandler}>
                    <input type="number" name="userId" value={userId} onChange={this.changeHandler}/><br/>
                    <input type="text" name="title" value={title} onChange={this.changeHandler}/><br/>
                    <input type="text" name="body" value={body} onChange={this.changeHandler}/><br/>
                    <button>Save</button>
                </form>
            </div>
        )
    }
}

export default CreatePost
