import React, { Component } from 'react'

class PostLayout extends Component {

    render() {
        return (
            <div className="col-md-4">
                <div className="card">
                    <div className="card-header bg-primary">
                        {this.props.title}
                    </div>
                    <div className="card-body bg-light">
                        {this.props.body}
                    </div>
                </div>
            </div>
        )
    }
}

export default PostLayout
