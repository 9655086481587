import React, { Component } from 'react';
import './App.css';
import Navbar from './components/layouts/Navbar';
import Home from './components/home/Home';
import {BrowserRouter, Route } from 'react-router-dom';
import Contact from './components/contact/Contact';
import PostLayout from './components/AppComponents/PostLayout';
import CreatePost from './components/AppComponents/CreatePost';
import Footer from './components/footer/Footer';
import About from './components/about/About';
import Gallery from './components/gallery/Gallery';
import Services from './components/services/Services';

export default class App extends Component {
  
  constructor(props){
    super(props)
  }
  render(){
  return (
    <div>
      <BrowserRouter>
        <Navbar />
        <Route path="/" exact={true} component={Home}/>
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route path="/gallery" component={Gallery} />
        <Route path="/services" component={Services} />
        <Route path="/posts" component={PostLayout} />
        <Route path="/add-post" component={CreatePost} />
        <Footer/>
      </BrowserRouter>
    </div>
  );
}
}

