import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Services extends Component {
    render() {
        return (
            <div>
                {/* <!-- page details --> */}
                <div class="using-border py-3">
                    <div class="inner_breadcrumb  ml-4">
                        <ul class="short_ls">
                            <li>
                                <Link to="/">Home</Link>
                                <span>/ /</span>
                            </li>
                            <li>Services</li>
                        </ul>
                    </div>
                </div>
                {/* <!-- //page details -->
                <!--service-one --> */}
                <section class="service-one py-lg-4 py-md-3 py-sm-3 py-3" id="service-one">
                    <div class="container py-lg-5 py-md-5 py-sm-4 py-3">
                        <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2 ">Our Services</h3>
                        <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
                            <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum
               </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-4 ser-cor-grid">
                                <div class="ser-sevice-inner text-center">
                                    <div class="sevice-one-inner">
                                        <span class="fa fa-hand-peace-o" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3">Finance Planning</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 ser-cor-grid">
                                <div class="ser-sevice-inner text-center">
                                    <div class="sevice-one-inner">
                                        <span class="fa fa-search" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3">Market research</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 ser-cor-grid">
                                <div class="ser-sevice-inner text-center">
                                    <div class="sevice-one-inner">
                                        <span class="fa fa-bar-chart" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3">Business Planning</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-4 mt-3">
                            <div class="col-lg-4 col-md-4 ser-cor-grid">
                                <div class="ser-sevice-inner text-center">
                                    <div class="sevice-one-inner">
                                        <span class="fa fa-check" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3"> Good Skills</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 ser-cor-grid">
                                <div class="ser-sevice-inner text-center">
                                    <div class="sevice-one-inner">
                                        <span class="fa fa-handshake-o" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3">Innovationh</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-4 ser-cor-grid">
                                <div class="ser-sevice-inner text-center">
                                    <div class="sevice-one-inner">
                                        <span class="fa fa-comment-o" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3"> Experts Help</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--// service-one -->
                <!-- service --> */}
                <section class="service py-lg-4 py-md-3 py-sm-3 py-3" id="service">
                    <div class="container py-lg-5 py-md-5 py-sm-4 py-3">
                        <div class="row">
                            <div class="col-lg-4 ser-blog-grid">
                                <div class="ser-sevice-grid">
                                    <div class="sevice-w3layouts-inner">
                                        <span class="fa fa-hand-peace-o" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3">Finance Planning</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 ser-blog-grid">
                                <div class="ser-sevice-grid">
                                    <div class="sevice-w3layouts-inner">
                                        <span class="fa fa-search" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3">Market research</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 ser-blog-grid">
                                <div class="ser-sevice-grid">
                                    <div class="sevice-w3layouts-inner">
                                        <span class="fa fa-bar-chart" aria-hidden="true"></span>
                                    </div>
                                    <div class="sevice-ser-txt">
                                        <h4 class="py-3">Business Planning</h4>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!--// service -->
                <!-- price --> */}
                <section class="price py-lg-4 py-md-3 py-sm-3 py-3" id="prices">
                    <div class="container py-lg-5 py-md-5 py-sm-4 py-3">
                        <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2">Our Price</h3>
                        <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
                            <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum </p>
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                                <div class="w3ls-bottom grid-two">
                                    <div class="price-images">
                                        <div class="table-txt-grid">
                                            <h4>GOLDEN</h4>
                                        </div>
                                        <div class="my-lg-4 my-3 price-w3layouts-table">
                                            <h4> <span class="sup">$</span><span class="number-price">60</span> / month </h4>
                                        </div>
                                        <div class="price-list-txt">
                                            <ul class="count">
                                                <li>Lorem ipsum </li>
                                                <li>Dolor sit </li>
                                                <li>Consectetuer</li>
                                                <li>Adipiscing</li>
                                                <li>Lorem ipsum </li>
                                            </ul>
                                        </div>
                                        <div class="view-price mt-3">
                                            <a href="contact.html" class=" scroll">Book Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                                <div class="w3ls-bottom grid-two">
                                    <div class="price-images">
                                        <div class="table-txt-grid">
                                            <h4>PREMIUM</h4>
                                        </div>
                                        <div class="my-lg-4 my-3 price-w3layouts-table">
                                            <h4> <span class="sup">$</span><span class="number-price">60</span> / month </h4>
                                        </div>
                                        <div class="price-list-txt">
                                            <ul class="count">
                                                <li>Lorem ipsum </li>
                                                <li>Dolor sit </li>
                                                <li>Consectetuer</li>
                                                <li>Adipiscing</li>
                                                <li>Lorem ipsum </li>
                                            </ul>
                                        </div>
                                        <div class="view-price mt-3">
                                            <a href="contact.html" class=" scroll">Book Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                                <div class="w3ls-bottom grid-one">
                                    <div class="price-images">
                                        <div class="table-txt-grid color-white">
                                            <h4>PLATINUM</h4>
                                        </div>
                                        <div class="my-lg-4 my-3 price-w3layouts-table">
                                            <h4> <span class="sup">$</span><span class="number-price">70</span> / month </h4>
                                        </div>
                                        <div class="price-list-txt price-grid-one">
                                            <ul class="count">
                                                <li>Lorem ipsum </li>
                                                <li>Dolor sit </li>
                                                <li>Consectetuer</li>
                                                <li>Adipiscing</li>
                                                <li>Lorem ipsum </li>
                                            </ul>
                                        </div>
                                        <div class="view-price color-black mt-3 ">
                                            <a href="contact.html" class=" scroll">Book Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                                <div class="w3ls-bottom grid-two">
                                    <div class="price-images">
                                        <div class="table-txt-grid">
                                            <h4>SILVER</h4>
                                        </div>
                                        <div class="my-lg-4 my-3 price-w3layouts-table">
                                            <h4> <span class="sup">$</span><span class="number-price">80</span> / month </h4>
                                        </div>
                                        <div class="price-list-txt">
                                            <ul class="count">
                                                <li>Lorem ipsum </li>
                                                <li>Dolor sit </li>
                                                <li>Consectetuer</li>
                                                <li>Adipiscing</li>
                                                <li>Lorem ipsum </li>
                                            </ul>
                                        </div>
                                        <div class="view-price mt-3">
                                            <a href="contact.html" class=" scroll">Book Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- //price --> */}
            </div>
        )
    }
}

