import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Contact extends Component {

    constructor(props) {
        super(props)
    
        // this.state = {
        //      email:'',
        //      password:''
        // }

        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            welcomeMsg:''
        }
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

  

    formSubmit = (e) =>{
        
        console.log(this.state);
        this.setState({
            welcomeMsg:"Thanks for your query. Will response you soon."
        })
        e.preventDefault();
    }
    
    render() {
        //const {password, email} =this.state 
         const {name, email, phone, message} =this.state 
        return (
            <div>
                {/* <br/>
                <form className="form-inline" onSubmit={this.formSubmit}>
                <label>Email address:</label>
                    <input type="email" className="form-control" placeholder="Enter email" value={email} onChange={this.onChangeEmail}/>
                <label >Password:</label>
                    <input type="password" className="form-control" placeholder="Enter password" name={password} onChange={this.onChangePassword}/>
                <button type="submit" className="btn btn-primary">Submit</button>
                </form> */}
                {/* <!-- page details --> */}
                <div class="using-border py-3">
                    <div class="inner_breadcrumb  ml-4">
                        <ul class="short_ls">
                            <li>
                                <Link to="/contact">Home</Link>
                                <span>/ /</span>
                            </li>
                            <li>Contact</li>
                        </ul>
                    </div>
                </div>
                {/* <!-- //page details -->
                <!--contact --> */}
                <section class="contact py-lg-4 py-md-3 py-sm-3 py-3" id="contact">
                    <div class="container py-lg-5 py-md-4 py-sm-4 py-3">
                        <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2">contact Us</h3>
                        <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
                            <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum
               </p>
                        </div>
                        <div class="contact-details">
                            <form onSubmit={this.formSubmit}>
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class=" form-group contact-forms">
                                            <input type="text" class="form-control" placeholder="Your Full Name" name="name" required value={name} onChange={this.changeHandler}/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group contact-forms">
                                            <input type="email" class="form-control" placeholder="Your Email" name="email" required value={email} onChange={this.changeHandler}/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="form-group contact-forms">
                                            <input type="text" class="form-control" placeholder="Your Phone" name="phone" required value={phone} onChange={this.changeHandler}/> 
                                        </div>
                                        </div>
                                    </div>
                                    <div class="form-group contact-forms">
                                    <textarea class="form-control" placeholder="Your Message" rows="3" required name="message" value={message} onChange={this.changeHandler}></textarea>
                                    </div>
                                    <button type="submit" class="btn sent-butnn">Send</button>
                                    <span className="text-success font-weight-bold"> &nbsp; &nbsp; {this.state.welcomeMsg}</span>
                                    </form>
                                        </div>
                                        <div class="row mt-lg-5 mt-md-4 mt-3">
                                            <div class="col-lg-6 col-md-6 col-sm-6 address_mail_footer_grids">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14760.196540387231!2d91.8330525!3d22.3517735!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcfa2de302a12091b!2sProgrammer&#39;s%20Media!5e0!3m2!1sen!2sbd!4v1605176781745!5m2!1sen!2sbd"></iframe>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6 address-grid">
                                                <div class="row address-contact-form">
                                                    <div class="col-lg-3 col-md-4 col-sm-4">
                                                        <div class="footer-icon text-center">
                                                            <span class="fa fa-home" aria-hidden="true"></span>
                                                        </div>
                                                    </div>
                                                    <div class=" footer-contact-list text-center col-lg-9 col-md-8 col-sm-8">
                                                        <p>Melbourne,south Brisbane,<br/>QLD 4101,Aurstralia.</p>
                     </div>
                                                    </div>
                                                    <div class="row address-contact-form mt-lg-4 mt-3">
                                                        <div class="col-lg-3 col-md-4 col-sm-4">
                                                            <div class="footer-icon text-center">
                                                                <span class="fa fa-phone" aria-hidden="true"></span>
                                                            </div>
                                                        </div>
                                                        <div class=" footer-contact-list text-center col-lg-9 col-md-8 col-sm-8">
                                                            <p>+ 1 (234) 567 8901</p>
                                                            <p>+ 1 (000) 555 9901</p>
                                                        </div>
                                                    </div>
                                                    <div class="row address-contact-form mt-lg-4 mt-3">
                                                        <div class="col-lg-3 col-md-4 col-sm-4">
                                                            <div class="footer-icon text-center">
                                                                <span class="fa fa-envelope" aria-hidden="true"></span>
                                                            </div>
                                                        </div>
                                                        <div class=" footer-contact-list text-center col-lg-9 col-md-8 col-sm-8">
                                                            <p><a href="mailto:info@example.com">info@example.com</a></p>
                                                            <p><a href="mailto:info@example.com">info2@example.com</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
      </section>
                                    {/* <!--// contact --> */}

            </div>
        )
    }
}
