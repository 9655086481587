import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Navbar extends Component {
    render() {
        return (
            // <nav className="navbar navbar-expand-md bg-dark navbar-dark">
            //     <Link className="navbar-brand" to="/">Home</Link>
            //     <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
            //         <span className="navbar-toggler-icon"></span>
            //     </button>
            //     <div className="collapse navbar-collapse" id="collapsibleNavbar">
            //         <ul className="navbar-nav">
            //             <li className="nav-item">
            //                 <Link className="nav-link" to="/contact">Contact</Link>
            //             </li>
            //             <li className="nav-item">
            //                 <Link className="nav-link" to="/add-post">Add Post</Link>
            //             </li>
            //         </ul>
            //     </div>
            // </nav>
            <div>
                <div class="container-fluid">
                    {/* <!-- nav --> */}
                    <nav>
                        <div id="logo">
                            <h1><Link class="" to="/"><span class="fa fa-ravelry mr-2" aria-hidden="true"></span>PMEDIA</Link></h1>
                        </div>
                        <label for="drop" class="toggle"> <i class="fa fa-bars" style={{color:'#17DD63', fontSize:32}}></i> </label>
                        <input type="checkbox" id="drop" />
                        <ul class="menu mt-2">
                            <li><Link class="active" to="/">Home</Link></li>
                            <li><Link to="/about">About</Link></li>
                            <li><Link to="/services">Services</Link></li>
                            <li><Link to="/gallery">Gallery</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>
                        </ul>
                    </nav>
                    {/* <!-- //nav --> */}
                </div>
               
      {/* <!-- //banner --> */}
            </div>
        )
    }
}
