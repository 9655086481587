import React, { Component } from 'react'
import axios from 'axios';
import PostLayout from '../AppComponents/PostLayout';

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { username: '' };
        this.state = {
            posts:[]
        }
    }

    componentDidMount(){
        axios.get('https://jsonplaceholder.typicode.com/posts')
        .then(response =>{
            this.setState({
                posts:response.data
            })
            console.log(response.data)
        })
        .catch(error =>{
        console.log(error)
        });
    }

    mySubmitHandler = (event) => {
        event.preventDefault();
        alert("You are submitting " + this.state.username);
    } 
    myChangeHandler = (event) => {
        this.setState({ username: event.target.value });
    }


    render() {
        const {posts} = this.state
        return (

            // <div className="container">
            //     <div className="row">
            //             {
            //                 posts.length?posts.map(post=>
            //                 <PostLayout key={post.id} id={post.id} title={post.title} body={post.body}/>
            //                 ):null
            //             }
            //     </div>
            // </div>    
            <div>
            
                <div id="demo" class="carousel slide" data-ride="carousel">
                    <ul class="carousel-indicators">
                        <li data-target="#demo" data-slide-to="0" class="active"></li>
                        <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li>
                    </ul>
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="assets/images/bb1.jpg" alt="Los Angeles" height="600"/>
                                <div class="carousel-caption">
                                    <h3>Los Angeles</h3>
                                    <p>We had such a great time in LA!</p>
                                </div>   
    </div>
                            <div class="carousel-item">
                            <img src="assets/images/bb2.jpg" alt="Chicago" height="600"/>
                                    <div class="carousel-caption">
                                        <h3>Chicago</h3>
                                        <p>Thank you, Chicago!</p>
                                    </div>   
    </div>
                                <div class="carousel-item">
                            <img src="assets/images/g11.jpg" alt="New York" height="600"/>
                                        <div class="carousel-caption">
                                            <h3>New York</h3>
                                            <p>We love the Big Apple!</p>
                                        </div>   
    </div>
                                </div>
                                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                                    <span class="carousel-control-prev-icon"></span>
                                </a>
                                <a class="carousel-control-next" href="#demo" data-slide="next">
                                    <span class="carousel-control-next-icon"></span>
                                </a>
                            </div>


                {/* <div class="main-top" id="home">
                <div class="headder-top">
                    <div class="main-banner">
                        <div class="container">
                            <div class="style-banner text-center">
                                <h4 class="mb-2">Welcome To Company</h4>
                                <p> Lorem ipsum dolor sit amet</p>
                            </div>
                            <div class="two-demo-button d-flex justify-content-center mt-lg-5 mt-md-4 mt-sm-4 mt-3">
                                <div class="read-buttn ">
                                    <a href="#service" class="btn scroll">Read More</a>
                                </div>
                                <div class="view-buttn">
                                    <a href="#about" class="btn scroll">About Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div> */}
                {/* <!-- about --> */}
      <section class="about py-lg-4 py-md-3 py-sm-3 py-3" id="about">
         <div class="container py-lg-5 py-md-4 py-sm-4 py-3">
            <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2">About Us</h3>
            <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
               <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum 
               </p>
            </div>
            <div class="row pt-lg-5 pt-md-4 pt-3">
               <div class="col-lg-3 col-md-6 text-center col-sm-6 corpo-about ">
                  <div class="position-relative about-top-grid">
                     <div class="about-icon-position">
                        <img src="assets/images/icon1.png" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-img-top">
                        <img src="assets/images/a1.jpg" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-wthree-about text-center mt-lg-4 mt-3">
                        <h5>Business Planning</h5>
                        <p class="mt-2">delectus reiciendis maiores alias consequatur aut.maiores alias</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 text-center corpo-about ">
                  <div class="position-relative about-top-grid">
                     <div class="about-icon-position">
                                        <img src="assets/images/icon2.png" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-img-top">
                                        <img src="assets/images/a2.jpg" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-wthree-about text-center mt-lg-4 mt-3">
                        <h5>Market research</h5>
                        <p class="mt-2">delectus reiciendis maiores alias consequatur aut.maiores alias</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 text-center corpo-about ">
                  <div class="position-relative about-top-grid">
                     <div class="about-icon-position">
                        <img src="assets/images/icon3.png" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-img-top">
                        <img src="assets/images/a3.jpg" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-wthree-about text-center mt-lg-4 mt-3">
                        <h5>Business 
                           Strategy
                        </h5>
                        <p class="mt-2">delectus reiciendis maiores alias consequatur aut.maiores alias</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 text-center corpo-about ">
                  <div class="position-relative about-top-grid">
                     <div class="about-icon-position">
                                        <img src="assets/images/icon4.png" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-img-top">
                                        <img src="assets/images/a4.jpg" alt="" class="img-fluid"/>
                     </div>
                     <div class="about-wthree-about text-center mt-lg-4 mt-3">
                        <h5>Finance Planning</h5>
                        <p class="mt-2">delectus reiciendis maiores alias consequatur aut.maiores alias</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!-- about  -->
      <!-- service --> */}
      <section class="service py-lg-4 py-md-3 py-sm-3 py-3" id="service">
         <div class="container py-lg-5 py-md-5 py-sm-4 py-3">
            <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2 clr">Our Services</h3>
            <div class="title-wls-text title-white text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
               <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum 
               </p>
            </div>
            <div class="row">
               <div class="col-lg-4 ser-blog-grid">
                  <div class="ser-sevice-grid">
                     <div class="sevice-alchemy-bd-inner">
                        <span class="fa fa-hand-peace-o" aria-hidden="true"></span>
                     </div>
                     <div class="sevice-ser-txt">
                        <h4 class="py-3">Finance Planning</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 ser-blog-grid">
                  <div class="ser-sevice-grid">
                     <div class="sevice-alchemy-bd-inner">
                        <span class="fa fa-search" aria-hidden="true"></span>
                     </div>
                     <div class="sevice-ser-txt">
                        <h4 class="py-3">Market research</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non</p>
                     </div>
                  </div>
               </div>
               <div class="col-lg-4 ser-blog-grid">
                  <div class="ser-sevice-grid">
                     <div class="sevice-alchemy-bd-inner">
                        <span class="fa fa-bar-chart" aria-hidden="true"></span>
                     </div>
                     <div class="sevice-ser-txt">
                        <h4 class="py-3">Business Planning</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum nibh urna, euismod ut ornare non</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!--// service -->
      <!-- price --> */}
      <section class="price py-lg-4 py-md-3 py-sm-3 py-3" id="prices">
         <div class="container py-lg-5 py-md-5 py-sm-4 py-3">
            <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2">Our Price</h3>
            <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
               <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum </p>
            </div>
            <div class="row">
               <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                  <div class="asl-bottom grid-two">
                     <div class="price-images">
                        <div class="table-txt-grid">
                           <h4>GOLDEN</h4>
                        </div>
                        <div class="my-lg-4 my-3 price-alchemy-bd-table">
                           <h4> <span class="sup">$</span><span class="number-price">60</span> / month </h4>
                        </div>
                        <div class="price-list-txt">
                           <ul class="count">
                              <li>Lorem ipsum </li>
                              <li>Dolor sit </li>
                              <li>Consectetuer</li>
                              <li>Adipiscing</li>
                              <li>Lorem ipsum </li>
                           </ul>
                        </div>
                        <div class="view-price mt-3">
                           <a href="contact.html" class=" scroll">Book Now</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                  <div class="asl-bottom grid-two">
                     <div class="price-images">
                        <div class="table-txt-grid">
                           <h4>PREMIUM</h4>
                        </div>
                        <div class="my-lg-4 my-3 price-alchemy-bd-table">
                           <h4> <span class="sup">$</span><span class="number-price">60</span> / month </h4>
                        </div>
                        <div class="price-list-txt">
                           <ul class="count">
                              <li>Lorem ipsum </li>
                              <li>Dolor sit </li>
                              <li>Consectetuer</li>
                              <li>Adipiscing</li>
                              <li>Lorem ipsum </li>
                           </ul>
                        </div>
                        <div class="view-price mt-3">
                           <a href="contact.html" class=" scroll">Book Now</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                  <div class="asl-bottom grid-one">
                     <div class="price-images">
                        <div class="table-txt-grid color-white">
                           <h4>PLATINUM</h4>
                        </div>
                        <div class="my-lg-4 my-3 price-alchemy-bd-table">
                           <h4> <span class="sup">$</span><span class="number-price">70</span> / month </h4>
                        </div>
                        <div class="price-list-txt price-grid-one">
                           <ul class="count">
                              <li>Lorem ipsum </li>
                              <li>Dolor sit </li>
                              <li>Consectetuer</li>
                              <li>Adipiscing</li>
                              <li>Lorem ipsum </li>
                           </ul>
                        </div>
                        <div class="view-price color-black mt-3 ">
                           <a href="contact.html" class=" scroll">Book Now</a>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 pricing-grid">
                  <div class="asl-bottom grid-two">
                     <div class="price-images">
                        <div class="table-txt-grid">
                           <h4>SILVER</h4>
                        </div>
                        <div class="my-lg-4 my-3 price-alchemy-bd-table">
                           <h4> <span class="sup">$</span><span class="number-price">80</span> / month </h4>
                        </div>
                        <div class="price-list-txt">
                           <ul class="count">
                              <li>Lorem ipsum </li>
                              <li>Dolor sit </li>
                              <li>Consectetuer</li>
                              <li>Adipiscing</li>
                              <li>Lorem ipsum </li>
                           </ul>
                        </div>
                        <div class="view-price mt-3">
                           <a href="contact.html" class=" scroll">Book Now</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!-- //price -->
      <!-- bussiness-tip --> */}
      <section class="busness-tip" id="busness-tip">
         <div class="container-fluid">
            <div class="row">
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-one pl-sm-0">
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">How long before the exit you must be planning it</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-two pr-sm-0">
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">Bringing a new CFO at the time of crisis</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">How long before the exit you must be planning it</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-two pr-sm-0">
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">Bringing a new CFO at the time of crisis</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-one pl-sm-0">
               </div>
            </div>
         </div>
      </section>
      {/* <!--//bussiness-tip -->
      <!-- clients --> */}
      <section class="clients py-lg-4 py-md-3 py-sm-3 py-3" id="clients">
         <div class="container py-lg-5 py-md-5 py-sm-4 py-3">
            <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2">Our Clients</h3>
            <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
               <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum </p>
            </div>
            <div class="row">
               <div class="col-lg-6 col-md-6 col-sm-6 clients-row-grid">
                  <div class="client-txt-color txt-center">
                     <p class="mb-md-4 mb-sm-3 mb-2"><span class="fa fa-quote-left" aria-hidden="true"></span> Lorem ipsum dolor sit amet, eiusmod tempor incididunt ut labore et consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore <span class="fa fa-quote-right" aria-hidden="true"></span></p>
                                    <img src="assets/images/c1.jpg" class="img-fluid" alt=""/>
                     <div class="client-txt-info mt-2">
                        <h4> Ted Willson</h4>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 col-md-6 col-sm-6 clients-row-grid">
                  <div class="client-txt-color txt-center">
                     <p class="mb-md-4 mb-sm-3 mb-2"><span class="fa fa-quote-left" aria-hidden="true"></span> Lorem ipsum dolor sit amet, eiusmod tempor incididunt ut labore et consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore <span class="fa fa-quote-right" aria-hidden="true"></span></p>
                                    <img src="assets/images/c2.jpg" class="img-fluid" alt=""/>
                     <div class="client-txt-info mt-2">
                        <h4> Ted Willson</h4>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!--//clients -->
      <!-- footer --> */}
    
                                                                {/* <!--//footer --> */}
            </div>
            
        )
    }
}
