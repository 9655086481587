import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class About extends Component {
    render() {
        return (
            <div>
                {/* <!-- page details --> */}
      <div class="using-border py-3">
         <div class="inner_breadcrumb  ml-4">
            <ul class="short_ls">
               <li>
                  <Link to="/">Home</Link>
                  <span>/ /</span>
               </li>
               <li>About</li>
            </ul>
         </div>
      </div>
      {/* <!-- //page details -->
      <!-- about --> */}
      <section class="about py-lg-4 py-md-3 py-sm-3 py-3">
         <div class="container py-lg-5 py-md-4 py-sm-4 py-3">
            <div class="row">
               <div class="col-lg-6 col-md-7 about-middle-txt">
                  <div class="wthree-about-two">
                     <p>delectus reiciendis maiores alias consequatur aut.maiores alias Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eiusmod delectus reiciendis maiores alias consequatur aut.maiores alias Lorem ipsum dolor sit amet, consectetur</p>
                  </div>
                  <div class="row mt-lg-4 mt-3">
                     <div class="col-lg-6 col-md-6 col-sm-6 abut-icon-mid">
                        <div class="list-icon-txt">
                           <ul>
                              <li class="pb-2">
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                              <li class="pb-2">
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                              <li class="pb-2">
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                              <li>
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-6 abut-icon-mid">
                        <div class="list-icon-txt">
                           <ul>
                              <li class="pb-2">
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                              <li class="pb-2">
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                              <li class="pb-2">
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                              <li>
                                 <span class="fa fa-thumbs-o-up" aria-hidden="true"></span> 
                                 <p>Lorem ipsum dolor sit </p>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-lg-6 col-md-5 about-middle-txt">
                  <div class="img-cols-grid row">
                     <div class="col-lg-6 col-md-6 col-sm-6 pr-0 color-img-cols">
                        <img src="assets/images/a1.jpg" alt="" class="img-fluid"/>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-6 pl-0 color-img-cols">
                        <img src="assets/images/a2.jpg" alt="" class="img-fluid"/>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-6 pr-0 color-img-cols">
                        <img src="assets/images/a3.jpg" alt="" class="img-fluid"/>
                     </div>
                     <div class="col-lg-6 col-md-6 col-sm-6 pl-0 color-img-cols">
                        <img src="assets/images/a4.jpg" alt="" class="img-fluid"/>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!--//about -->
      <!-- bussiness-tip --> */}
      <section class="busness-tip" id="busness-tip">
         <div class="container-fluid">
            <div class="row">
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-one pl-sm-0">
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">How long before the exit you must be planning it</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-two pr-sm-0">
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">Bringing a new CFO at the time of crisis</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
            </div>
            <div class="row">
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">How long before the exit you must be planning it</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-two pr-sm-0">
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 mid-text-info">
                  <h4 class="mb-lg-4 mb-3">Bringing a new CFO at the time of crisis</h4>
                  <p>Lorem Ipsum is simply text the printing and typesetting standard industry. Lorem Ipsum has been the industry's standard
                     dummy text.
                  </p>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 busness-tip-one pl-sm-0">
               </div>
            </div>
         </div>
      </section>
      {/* <!--//bussiness-tip -->
      <!-- team --> */}
      <section class="team py-lg-4 py-md-3 py-sm-3 py-3">
         <div class="container py-lg-5 py-md-4 py-sm-4 py-3">
            <h3 class="title text-center mb-md-4 mb-sm-3 mb-3 mb-2">Our Team</h3>
            <div class="title-wls-text text-center mb-lg-5 mb-md-4 mb-sm-4 mb-3">
               <p >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et Lorem ipsum </p>
            </div>
            <div class="row">
               <div class="col-lg-3 col-md-6 col-sm-6 team-row-grid">
                  <div class="team-grid">
                     <div class="team-image mb-3">
                        <img src="assets/images/t1.jpg" alt="" class="img-fluid"/>
                        <div class="social-icons">
                           <a href="#"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-linkedin" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-google-plus" aria-hidden="true"></span></a>
                        </div>
                     </div>
                     <h4>William Jon</h4>
                     <p class="mt-2">Founder</p>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 team-row-grid">
                  <div class="team-grid">
                     <div class="team-image mb-3">
                        <img src="assets/images/t2.jpg" alt="" class="img-fluid"/>
                        <div class="social-icons">
                           <a href="#"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-linkedin" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-google-plus" aria-hidden="true"></span></a>
                        </div>
                     </div>
                     <h4>Sam Jacob</h4>
                     <p class="mt-2">Director</p>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 team-row-grid">
                  <div class="team-grid">
                     <div class="team-image mb-3">
                        <img src="assets/images/t3.jpg" alt="" class="img-fluid"/>
                        <div class="social-icons">
                           <a href="#"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-linkedin" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-google-plus" aria-hidden="true"></span></a>
                        </div>
                     </div>
                     <h4>Liam Max</h4>
                     <p class="mt-2">Markting</p>
                  </div>
               </div>
               <div class="col-lg-3 col-md-6 col-sm-6 team-row-grid">
                  <div class="team-grid ">
                     <div class="team-image mb-3">
                        <img src="assets/images/t4.jpg" alt="" class="img-fluid"/>
                        <div class="social-icons">
                           <a href="#"><span class="fa fa-facebook" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-twitter" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-linkedin" aria-hidden="true"></span></a>
                           <a href="#"><span class="fa fa-google-plus" aria-hidden="true"></span></a>
                        </div>
                     </div>
                     <h4>William jon</h4>
                     <p class="mt-2">Manager</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      {/* <!-- //team --> */}
            </div>
        )
    }
}

